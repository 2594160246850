import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'
import {
  useRouteQueryParams,
  useRequest,
  useReactRouter,
  useRoutePathParams,
  useReduxAction,
  useEvent,
} from 'hooks'
import { useTranslation } from 'react-i18next'
import { READY_FOR_PHONE_VERIFICATION } from 'constants/qualityControl'
import {
  fetchPhoneVerificationQuestions,
  advanceCurrentPacketToPhoneVerification,
  fetchPacket,
  fetchScan,
} from 'store/qcScans/actions'
import {
  getCurrentPacket,
  getCurrentScan,
  getPhoneVerificationQuestions,
  getSortedScanIds,
  getCurrentPacketPhoneVerificationComplete,
  getCurrentPacketTurfId,
} from 'store/qcScans/reducer'
import { alphaNumericCompare } from 'utils/array'
import {
  getIsScanExcludedByPreviousNotContactedResponse,
  getIsScanExcludedByVisualReview,
} from 'store/qcScans/utils'
import { CardError } from 'components/'
import {
  NOT_CONTACTED_REASON,
  REGISTERED,
} from 'constants/qcCallScriptsConfig/phoneVerificationQuestions'
import CallScriptsContextProvider from 'qualityControl/callScripts/CallScriptsContext'
import { Font } from '@politechdev/blocks-design-system'
import { withReloadableSession } from './ReloadableSession'
import VoipCallPanel from '../callPanels/VoipCallPanel/VoipCallPanel'
import ExternalCallPanel from '../callPanels/ExternalCallPanel/ExternalCallPanel'
import PacketDetails from '../PacketDetails/PacketDetails'
import LogCallControls from '../LogCallControls/LogCallControls'
import CallScript from '../CallScript/CallScript'
import { usePhoneVerificationCall } from '../../usePhoneVerificationCall'
import { ScanPdfViewer } from '../../../PdfViewer'
import ScanHistoryDetails from '../ScanHistoryDetails/ScanHistoryDetails'
import ScanViewerHeader from '../../../ScanViewHeader/ScanViewerHeader'
import RegistrantProfile from '../RegistrantProfile/RegistrantProfile'
import PacketPaginationControls from '../PacketPaginationControls/PacketPaginationControls'
import SubmitPhoneVerificationBanner from '../SubmitForDeliveryBanner/SubmitForDeliveryBanner'
import CompleteQCModal from '../CompleteQCModal/CompleteQCModal'
import styles from './PhoneVerificationCallSession.module.scss'
import { getCallableScansCount, getNextCallableScanIndex } from './utils'

const PhoneVerificationSession = ({
  currentPacket = {},
  turfId,
  isPhoneVerificationComplete,
  fetchPhoneVerificationQuestions,
  questions,
  advanceCurrentPacketToPhoneVerification,
  allScanIds,
  currentScan,
}) => {
  const { t } = useTranslation()

  const {
    lastActiveCallRecordId,
    createCallRecord,
    endCallRecord,
    submitPhoneVerification,
    contactedStatus,
    setContactedStatus,
    verificationResponses,
    setVerificationResponses,
    hasEditedChanges,
    setHasEditedChanges,
    currentCallId,
    callTimer,
  } = usePhoneVerificationCall()

  const { match } = useReactRouter()
  const [{ packetId }] = useRoutePathParams()
  const [queryParams, setQueryParams] = useRouteQueryParams()
  const [initiallyCallableScanIds, setInitiallyCallableScansIds] = useState([])
  const [sessionScanCallMap, setSessionScanCallMap] = useState({})
  const [phoneNumber, setPhoneNumber] = useState('')
  const [notesValue, setNotesValue] = useState('')

  const [overrideIsEveryScanCalled, setOverrideIsEveryScanCalled] =
    useState(false)

  const [isDeliveryDeferred, setIsDeliveryDeferred] = useState(false)

  const scanId = +queryParams.scanId

  const [isPortalAvailable, setPortalAvailable] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
    setPortalAvailable(true)
  }, [])

  const { makeRequest: fetchQuestions, response: questionsResponse } =
    useRequest(fetchPhoneVerificationQuestions)

  useEffect(() => {
    if (turfId) {
      fetchQuestions(turfId)
    }
  }, [turfId])

  const reduxFetchPacket = useReduxAction(fetchPacket)
  const {
    makeRequest: requestPacket,
    isRequestComplete: hasFetchedPacket,
    hasErrors: hasFetchPacketErrors,
  } = useRequest(reduxFetchPacket, {
    onSuccess: packet => {
      const verifyingId = packet.turf.phone_verification_questions.find(
        ({ question }) => question === REGISTERED
      ).id

      const callableScanIds = packet.scans
        .filter(
          scan =>
            !getIsScanExcludedByVisualReview(scan) &&
            !getIsScanExcludedByPreviousNotContactedResponse(scan) &&
            !scan.phone_verification_responses.find(
              r => r.phone_verification_question_id === verifyingId
            )
        )
        .map(({ id }) => id)

      if (
        scanId &&
        queryParams.source === 'flags' &&
        !callableScanIds.includes(scanId)
      ) {
        callableScanIds.push(scanId)
      }

      callableScanIds.sort(alphaNumericCompare)
      setInitiallyCallableScansIds(callableScanIds)
    },
  })

  const reduxFetchScan = useReduxAction(fetchScan)
  const { makeRequest: requestScan } = useRequest(reduxFetchScan, {
    onSuccess: scan => {
      const phoneNumber =
        get(scan, 'form.pledge_card_metadata.phone_number') ||
        get(scan, 'form.phone_number') ||
        ''

      setPhoneNumber(phoneNumber.replaceAll(/\D/g, ''))
      setHasEditedChanges(false)
    },
  })

  useEffect(() => {
    requestPacket(packetId)
  }, [packetId])

  useEffect(() => {
    if (scanId) {
      requestScan(scanId)
    }
  }, [packetId, scanId])

  useEffect(() => {
    if (questionsResponse && currentScan.id) {
      const questionsIdKeyMap = Object.fromEntries(
        questionsResponse.turf.phone_verification_questions.map(
          ({ id, question }) => [id, question]
        )
      )

      const pvrs = currentScan.phone_verification_responses

      const callId = get(sessionScanCallMap, currentScan.id)
      const responses = pvrs.filter(response => callId === response.call.id)

      if (responses.length) {
        const responseEntries = responses.map(response => [
          response.phone_verification_question_id,
          response.response,
        ])
        setVerificationResponses(Object.fromEntries(responseEntries))

        let contacted = 't'
        responses.forEach(pvr => {
          const questionKey =
            questionsIdKeyMap[pvr.phone_verification_question_id]

          if (questionKey === NOT_CONTACTED_REASON) {
            contacted = 'f'
          }

          if (pvr.notes) {
            setNotesValue(pvr.notes)
          }
        })

        setContactedStatus(contacted)
      }
    }
  }, [questionsResponse, currentScan?.id])

  const [lastCallableScanData, setLastCallableScanData] = useState({
    position: 0,
    total: 0,
  })

  useEffect(() => {
    const currentScanIndexInCallableScans =
      initiallyCallableScanIds.indexOf(scanId)
    const doesScanExistInCallableScans = currentScanIndexInCallableScans !== -1

    if (doesScanExistInCallableScans) {
      setLastCallableScanData({
        position: currentScanIndexInCallableScans,
        total: initiallyCallableScanIds.length,
      })
    }
  }, [initiallyCallableScanIds, scanId])

  useEffect(() => {
    if (!initiallyCallableScanIds.length) return

    const firstCallableScanId = initiallyCallableScanIds[0]

    const currentScanIndexInCallableScans =
      initiallyCallableScanIds.indexOf(scanId)
    const isScanInCallableScans = currentScanIndexInCallableScans !== -1

    const shouldPushToFirstScan =
      (!scanId || !isScanInCallableScans) && !!firstCallableScanId

    if (shouldPushToFirstScan) {
      setQueryParams({ ...queryParams, scanId: firstCallableScanId }, 'replace')
    }
  }, [scanId, initiallyCallableScanIds])

  useEffect(() => {
    if (currentPacket?.shift.status === READY_FOR_PHONE_VERIFICATION) {
      advanceCurrentPacketToPhoneVerification()
    }
  }, [currentPacket])

  const allScansScanIndex = allScanIds.indexOf(scanId)
  const displayScanIndex = initiallyCallableScanIds
    ? initiallyCallableScanIds.indexOf(scanId)
    : allScansScanIndex

  const currentScanIndexInCallableScans =
    initiallyCallableScanIds.indexOf(scanId)

  const isScanInCallableScans = currentScanIndexInCallableScans !== -1

  const callableScanIndex = !isScanInCallableScans
    ? lastCallableScanData.position
    : currentScanIndexInCallableScans

  const callableScanCount = !isScanInCallableScans
    ? lastCallableScanData.total
    : initiallyCallableScanIds.length

  const changeScanPage = useEvent(delta => {
    const nextIndex = callableScanIndex + delta
    setQueryParams({
      ...queryParams,
      scanId: initiallyCallableScanIds[nextIndex],
    })
  })

  const goToNextPage = () => {
    changeScanPage(1)
  }

  const goToPreviousPage = () => {
    changeScanPage(-1)
  }

  const goToNextAvailableScan = () => {
    const nextCallableScanIndex = getNextCallableScanIndex(
      currentScanIndexInCallableScans,
      initiallyCallableScanIds,
      sessionScanCallMap
    )

    if (nextCallableScanIndex !== null) {
      setQueryParams({
        ...queryParams,
        scanId: initiallyCallableScanIds[nextCallableScanIndex],
      })
    }
  }

  const allScansCountText = `${t('Scan')} ${
    (allScanIds.indexOf(scanId) === -1 ? 0 : allScanIds.indexOf(scanId)) + 1
  } of ${allScanIds.length} ${t('in packet')}`

  const callableScanCountText = `${t('Scan')} ${
    callableScanIndex + 1
  } of ${callableScanCount} ${t('in call queue')}`

  const isEveryScanCalled = initiallyCallableScanIds.every(
    scanId => !!sessionScanCallMap[scanId]
  )

  const callableScansCount = getCallableScansCount(
    currentPacket,
    questions[REGISTERED].id
  )

  const isSubmittable = callableScansCount === 0 || isPhoneVerificationComplete

  const showCompleteQCModal =
    (isSubmittable && !isDeliveryDeferred) ||
    (!overrideIsEveryScanCalled && isEveryScanCalled)

  const closeIsEveryScanCalled = () => {
    setOverrideIsEveryScanCalled(true)
  }

  const showEditingUi = !!sessionScanCallMap[currentScan.id]

  const recordingEnabled =
    !!currentPacket.turf.voter_registration_config
      .record_voip_phone_verification_calls

  const hasQuestionsConfigured = !!Object.values(questions || {}).some(
    question => !!question.id
  )

  if (hasFetchPacketErrors) {
    return null
  }

  return (
    <div>
      <div>
        <SubmitPhoneVerificationBanner
          show={hasFetchedPacket && isPhoneVerificationComplete}
          currentPacket={currentPacket}
        />
        <div className={styles.session}>
          <div className={styles.sidebar}>
            <RegistrantProfile />
            <ScanViewerHeader
              customTitle={
                <div className={styles['scan-viewer-header']}>
                  <p className={styles['scan-viewer-header__title']}>
                    {callableScanCountText}
                  </p>
                  <p className={styles['scan-viewer-header__aside']}>
                    ({allScansCountText})
                  </p>
                </div>
              }
              scansCount={callableScanCount}
              currentScanIndex={displayScanIndex}
              scanNumber={currentScan.scan_number}
            />
            <ScanPdfViewer
              scanUrl={currentScan.file_url}
              removedAt={currentScan.file_locator?.metadata?.redacted_at}
              isInteractive
            />
            <PacketDetails />
            <ScanHistoryDetails />
          </div>
          <div>
            {match.params.mode === 'voip' && recordingEnabled && (
              <div className={styles.notice}>
                <Font.Copy>{t('Notice: Calls will be recorded')}</Font.Copy>
              </div>
            )}
            <CallScriptsContextProvider>
              <CallScript
                setHasEditedChanges={setHasEditedChanges}
                setContactedStatus={setContactedStatus}
                setVerificationResponses={setVerificationResponses}
                verificationResponses={verificationResponses}
                questions={questions}
                phoneNumber={phoneNumber}
              />
            </CallScriptsContextProvider>
          </div>
          <CompleteQCModal
            show={hasFetchedPacket && showCompleteQCModal}
            isSubmittable={isSubmittable}
            isEveryScanCalled={isEveryScanCalled}
            currentPacket={currentPacket}
            closeIsEveryScanCalled={closeIsEveryScanCalled}
            setIsDeliveryDeferred={setIsDeliveryDeferred}
          />
        </div>
      </div>
      <div className={styles.controls}>
        <div className={styles.dialer__wrapper}>
          <div className={styles.dialer}>
            <CardError
              hide={hasQuestionsConfigured}
              message={t("We're unable to retrieve call questions.")}
            />
            {match.params.mode === 'voip' && hasQuestionsConfigured && (
              <VoipCallPanel
                packetId={currentPacket.id}
                recordingEnabled={recordingEnabled}
                isPortalAvailable={isPortalAvailable}
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                isEditing={showEditingUi}
                createCallRecord={createCallRecord}
                endCallRecord={endCallRecord}
                currentCallId={currentCallId}
                callTimer={callTimer}
                submitPhoneVerification={() =>
                  submitPhoneVerification.makeRequest(lastActiveCallRecordId)
                }
              />
            )}
            {match.params.mode === 'external' && hasQuestionsConfigured && (
              <ExternalCallPanel
                isPortalAvailable={isPortalAvailable}
                currentPacket={currentPacket}
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                isEditing={showEditingUi}
                createCallRecord={createCallRecord}
                endCallRecord={endCallRecord}
                currentCallId={currentCallId}
                callTimer={callTimer}
                submitPhoneVerification={() =>
                  submitPhoneVerification.makeRequest(lastActiveCallRecordId)
                }
              />
            )}
          </div>
          <LogCallControls
            questions={questions}
            isEditing={showEditingUi}
            editingCallId={sessionScanCallMap[currentScan.id]}
            setSessionScanCallMap={setSessionScanCallMap}
            goToNextScan={goToNextAvailableScan}
            phoneNumber={phoneNumber}
            notesValue={notesValue}
            setNotesValue={setNotesValue}
            hasEditedChanges={hasEditedChanges}
            setOverrideIsEveryScanCalled={setOverrideIsEveryScanCalled}
            getPacket={() => reduxFetchPacket(packetId)}
            setContactedStatus={setContactedStatus}
            contactedStatus={contactedStatus}
            setVerificationResponses={setVerificationResponses}
            verificationResponses={verificationResponses}
            createCallRecord={createCallRecord}
            endCallRecord={endCallRecord}
            submitPhoneVerification={submitPhoneVerification}
            lastActiveCallRecordId={lastActiveCallRecordId}
          />
        </div>
        <div className={styles.footer}>
          <div
            id="pv__call_panel_call_actions_portal_target"
            className={styles.footer__pagination}
          />
          <PacketPaginationControls
            currentScanIndex={callableScanIndex}
            scansCount={callableScanCount}
            goToNext={goToNextPage}
            goToPrevious={goToPreviousPage}
            callQueueAllScansCountText={allScansCountText}
          />
        </div>
      </div>
    </div>
  )
}

export default connect(
  state => ({
    currentPacket: getCurrentPacket(state),
    currentScan: getCurrentScan(state),
    isPhoneVerificationComplete:
      getCurrentPacketPhoneVerificationComplete(state),
    questions: getPhoneVerificationQuestions(state),
    allScanIds: getSortedScanIds(state),
    turfId: getCurrentPacketTurfId(state),
  }),
  {
    advanceCurrentPacketToPhoneVerification,
    fetchPhoneVerificationQuestions,
  }
)(withReloadableSession(PhoneVerificationSession))
