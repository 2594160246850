import { useRequest, useTimer } from 'hooks'
import { useState } from 'react'
import {
  createPhoneVerificationCall,
  endPhoneVerificationCall,
} from 'requests/phoneVerificationCalls'

export const getDisconnectedDateTime = (callCreatedAt, durationInSeconds) => {
  if (!callCreatedAt) {
    return undefined
  }

  const createdAtDate = new Date(
    callCreatedAt.match(/Z$/) ? callCreatedAt : `${callCreatedAt}Z`
  )
  const disconnectedDateTime = new Date(createdAtDate)

  disconnectedDateTime.setSeconds(
    createdAtDate.getSeconds() + durationInSeconds
  )

  return disconnectedDateTime.toISOString()
}

export const usePhoneVerificationCall = () => {
  const [currentCallId, setCurrentCallId] = useState(null)
  const [lastActiveCallRecordId, setLastActiveCallRecordId] = useState(null)

  const [callCreatedAt, setCallCreatedAt] = useState(null)

  const [contactedStatus, setContactedStatus] = useState('')
  const [verificationResponses, setVerificationResponses] = useState({})

  const [hasEditedChanges, setHasEditedChanges] = useState(false)

  const callTimer = useTimer()

  const createCallRecord = useRequest(
    (callId, params = { fields: [] }) =>
      createPhoneVerificationCall(callId, {
        ...params,
        fields: [...params.fields, 'created_at'],
      }),
    {
      onSuccess: response => {
        const call = response['quality_control/phone_verification_call']
        setLastActiveCallRecordId(call.id)
        setCurrentCallId(call.id)
        setCallCreatedAt(call.created_at)
      },
    }
  )

  const endCallRecord = () => {
    setCurrentCallId(null)
  }

  const submitPhoneVerification = useRequest(
    (callId, params = {}) => {
      const disconnectedDateTime = getDisconnectedDateTime(
        callCreatedAt,
        callTimer.secondsElapsed
      )

      if (disconnectedDateTime) {
        params.disconnected_at = disconnectedDateTime
      }

      return endPhoneVerificationCall(callId, params)
    },
    {
      onSuccess: () => {
        setCurrentCallId(null)
        setLastActiveCallRecordId(null)
        setCallCreatedAt(null)
      },
    }
  )

  return {
    lastActiveCallRecordId,
    createCallRecord,
    endCallRecord,
    submitPhoneVerification,
    contactedStatus,
    setContactedStatus,
    verificationResponses,
    setVerificationResponses,
    hasEditedChanges,
    setHasEditedChanges,
    currentCallId,
    callTimer,
  }
}
