/* eslint-disable no-use-before-define */
import { useState, useEffect } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useRequest } from 'hooks/useRequest'
import useEvent from 'hooks/useEvent'
import { useReactRouter, useRoutePathParams } from 'hooks/router'
import {
  ViewContainer,
  Uploader,
  Sheet,
  DetailItem,
  CardError,
  View,
  CountySelectFieldDEPRECATED,
} from 'components'
import {
  Font,
  ContentBlock,
  TextBlock,
  FieldBlock,
  Section,
} from '@politechdev/blocks-design-system'
import { dateFormat } from 'utils/constants'
import { fetchShift, postPacket, postPetitionPacket } from 'requests/shifts'
import { defaultTo } from 'lodash'
import styles from './ScanUpload.module.scss'
import { getPageFromFilename, getTotalPagesFromFilename } from './utils'
import { DataEntryUploadStorage } from './storageUtils'
import { SHIFT_TYPE } from './constants'

const ScanUpload = () => {
  const { t } = useTranslation()
  const { history } = useReactRouter()

  const [{ id: shiftId, shiftType }] = useRoutePathParams()

  const { makeRequest: fetchShiftDetails, response: shiftResponse } =
    useRequest(fetchShift)

  const currentShift = defaultTo(shiftResponse?.shift, {})

  const usesLegacyEntry =
    currentShift.turf?.voter_registration_config?.uses_legacy_entry

  const isSplitByClient =
    !usesLegacyEntry && shiftType === SHIFT_TYPE.REGISTRATION
  const [files, setFiles] = useState(null)
  const [county, setCounty] = useState(null)

  useEffect(() => {
    shiftId &&
      fetchShiftDetails(shiftId, {
        fields: [
          'id',
          'shift_start',
          { canvasser: 'full_name' },
          { turf: ['name', 'voter_registration_config'] },
          { location: 'state' },
        ],
      })
  }, [shiftId])

  useEffect(() => {
    handleIfDataEntry()
  }, [files])

  const handleIfDataEntry = useEvent(() => {
    if (shiftType === SHIFT_TYPE.REGISTRATION && files) {
      if (files.every(page => page !== null)) {
        history.push(
          `/collect/voter_registration/shifts/${currentShift.id}/packet_information`
        )
      }
    }
  })

  const {
    makeRequest: createScanReq,
    hasErrors,
    isLoading,
    errors,
  } = useRequest(
    fileData => {
      if (shiftType === SHIFT_TYPE.REGISTRATION) {
        return postPacket(currentShift.id, { file_locator: fileData })
      }

      return postPetitionPacket({
        shift_id: currentShift.id,
        file_locator: fileData,
        county,
      })
    },
    {
      onSuccess: async () => {
        if (shiftType === SHIFT_TYPE.REGISTRATION) {
          history.push(
            `/collect/voter_registration/shifts/${currentShift.id}/packet_information`
          )
        } else {
          history.push(`/collect/petitions/shifts`)
        }
      },
    },
    {
      reportErrors: false,
    }
  )

  const handleSuccesfulUpload = useEvent(async (fileData, url) => {
    isSplitByClient
      ? handleSeparatedScans(fileData, url)
      : createScanReq(fileData)
  })

  const handleSeparatedScans = async (fileData, url) => {
    const page = getPageFromFilename(fileData.metadata.filename)
    const totalPages = getTotalPagesFromFilename(fileData.metadata.filename)

    await DataEntryUploadStorage.addRecordToStorage(currentShift.id, page, {
      ...fileData,
      url,
    })

    setFiles(files => {
      let newFiles = files
      if (!newFiles || newFiles.length !== totalPages) {
        newFiles = new Array(totalPages).fill(null)
      }
      newFiles[page] = page
      return [...newFiles]
    })
  }

  const errorMessages = Object.entries(errors).map(([errorKey, message]) => {
    if (errorKey === 'file_hash' && message === 'has already been taken') {
      return 'This packet has already been uploaded to Blocks'
    }

    return message
  })

  const { turf, canvasser, shift_start, location } = currentShift
  const isToEarlyForUpload = moment()
    .startOf('day')
    .isBefore(moment(shift_start).startOf('day'))

  let endpoint
  if (isSplitByClient && shiftType === SHIFT_TYPE.REGISTRATION) {
    endpoint =
      '/system/files/upload?url=true&metadata_generator=pledge_card&content_type=application/pdf'
  } else if (shiftType === SHIFT_TYPE.REGISTRATION) {
    endpoint =
      '/system/files/upload?metadata_generator=voter_registration&url=true'
  } else {
    endpoint = '/system/files/upload?metadata_generator=petitions'
  }

  const requiresCounty = shiftType === SHIFT_TYPE.PETITION && !county

  return (
    JSON.stringify(currentShift) !== '{}' && (
      <View loading={false}>
        <div>
          <ViewContainer>
            <Sheet title={t('Upload scans')}>
              <ContentBlock>
                <CardError
                  hide={!hasErrors}
                  hideSupportText={errorMessages.length}
                  message={errorMessages.map(t).join(', ')}
                />
              </ContentBlock>
              <div className="shifts__upload-scans">
                <div className={styles.uploader}>
                  <Uploader
                    label={t('Scan')}
                    onUpload={handleSuccesfulUpload}
                    autoUpload
                    error={hasErrors}
                    fileTypes={['.pdf']}
                    endpoint={endpoint}
                    disabled={isToEarlyForUpload || isLoading || requiresCounty}
                    stringifyData={false}
                    fileValidator={file => {
                      // eslint-disable-next-line no-useless-escape
                      const invalidChars = file.name.match(/[^\w\.]/)

                      if (invalidChars) {
                        return `Filename contains invalid characters: ${invalidChars.join(
                          ', '
                        )}`
                      }
                    }}
                    splitPDFs={
                      isSplitByClient && shiftType === SHIFT_TYPE.REGISTRATION
                    }
                  />
                  {isToEarlyForUpload ? (
                    <TextBlock className={styles.text}>
                      <Font.Copy muted>
                        {t(
                          'Cannot upload packet because shift date/time has not occurred yet.'
                        )}
                      </Font.Copy>
                    </TextBlock>
                  ) : null}
                </div>
                <div className="shifts__upload-scans__meta">
                  <Section label={t('Shift info')}>
                    <DetailItem label={t('Office of origin')}>
                      {turf?.name}
                    </DetailItem>
                    <DetailItem label={t('Canvasser')}>
                      {canvasser?.full_name}
                    </DetailItem>
                    <DetailItem label={t('Shift date')}>
                      {moment(shift_start).format(dateFormat)}
                    </DetailItem>
                  </Section>
                  {shiftType === SHIFT_TYPE.PETITION ? (
                    <FieldBlock>
                      <CountySelectFieldDEPRECATED
                        required
                        error={requiresCounty}
                        label={t('County')}
                        county={county}
                        onSelect={setCounty}
                        state={location?.state}
                      />
                    </FieldBlock>
                  ) : null}
                </div>
              </div>
            </Sheet>
          </ViewContainer>
        </div>
      </View>
    )
  )
}

export default ScanUpload
