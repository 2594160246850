import { useEffect, createContext, useContext, useState, useRef } from 'react'
import { useForm } from 'contexts'
import { FILTER_TYPES } from '../constants'
import { buildUIFilters, buildAPIFilters } from './utils'

const ListFilterContext = createContext()

const sortByChainId = (a, b) => {
  if (a.chainId && b.chainId) {
    if (a.chainId < b.chainId) return -1
    if (a.chainId > b.chainId) return 1
    return 0
  }
  if (a.chainId && !b.chainId) return -1
  if (b.chainId && !a.chainId) return 1
  return 0
}

const removeDuplicateFilters = (filters, filter) => {
  const existingFilter = filters.find(
    ({ id, chainId }) => filter.id === id && filter.chainId === chainId
  )
  if (existingFilter) return filters

  return filters.concat(filter)
}

export const ListFilterProvider = ({
  group,
  initialFilters = {},
  children,
}) => {
  const { setField, formData } = useForm()
  const filters = buildUIFilters(formData[group.id] || initialFilters)

  const setFilters = newFilters => {
    setField(buildAPIFilters(newFilters), group.id)
    setField(!newFilters.some(filter => !filter.isValid), group.validationKey)
  }

  useEffect(() => {
    setField(initialFilters, group.id)
  }, [])

  const [chainingFilter, setChainingFilter] = useState(null)

  const setFiltersChainId = (chainingFilters, nextChainId) => {
    setFilters(
      filters
        .map(filter =>
          chainingFilters.find(
            ({ id, chainId }) => id === filter.id && chainId === filter.chainId
          )
            ? {
                ...filter,
                chainId: nextChainId,
              }
            : filter
        )
        .reduce(removeDuplicateFilters, [])
        .sort(sortByChainId)
    )
  }

  const nextChainId =
    Math.max(...filters.map(filter => filter.chainId).filter(Boolean), 0) + 1

  const startChaining = filter => {
    setChainingFilter(filter)
  }

  const endChaining = () => {
    setChainingFilter(null)
  }

  const addChainLink = filter => {
    const chainId = filter.chainId || nextChainId

    setFiltersChainId([filter, chainingFilter], chainId)
    endChaining()
  }

  const removeChainGroup = (chainId, filterProcess = filter => filter) => {
    setFilters(
      filters
        .filter(filterProcess)
        .map(filter => {
          if (!filter.chainId) return filter
          if (filter.chainId === chainId) {
            return {
              ...filter,
              chainId: undefined,
            }
          }
          if (filter.chainId > chainId) {
            return {
              ...filter,
              chainId: filter.chainId - 1,
            }
          }
          return filter
        })
        .reduce(removeDuplicateFilters, [])
        .sort(sortByChainId)
    )
  }

  const removeChainLink = filter => {
    const chainedFilters = filters.filter(
      ({ id, chainId }) => filter.chainId === chainId && filter.id !== id
    )

    if (chainedFilters.length <= 1) {
      removeChainGroup(filter.chainId)
    } else {
      setFiltersChainId([filter], undefined)
    }
  }

  const addFilter = filter => {
    setFilters([...filters, filter])
  }

  const removeFilter = filter => {
    if (chainingFilter?.id === filter.id) {
      endChaining()
    }

    const chainedFilters = filters.filter(
      ({ id, chainId }) => filter.chainId === chainId && filter.id !== id
    )

    const removeProcess = ({ id, chainId }) =>
      id !== filter.id || chainId !== filter.chainId

    if (filter.chainId && chainedFilters.length <= 1) {
      removeChainGroup(filter.chainId, removeProcess)
    } else {
      setFilters(filters.filter(removeProcess))
    }
  }

  const setCallArgumentBatchesRef = useRef([])
  const setFilterTimeoutRef = useRef()

  const _replaceFilter = (filters, filter, value, meta) =>
    filters.map(existingFilter => {
      if (
        existingFilter.id === filter.id &&
        existingFilter.chainId === filter.chainId
      ) {
        if (meta) {
          return {
            ...existingFilter,
            value,
            meta,
          }
        }

        return { ...existingFilter, value }
      }

      return existingFilter
    })

  const setFilterValue = (filter, value, meta) => {
    if (setFilterTimeoutRef.current) {
      clearTimeout(setFilterTimeoutRef.current)
    }
    setCallArgumentBatchesRef.current.push([filter, value, meta])

    setFilterTimeoutRef.current = setTimeout(() => {
      const batchArguments = setCallArgumentBatchesRef.current

      let newFilters = filters

      batchArguments.forEach(([filter, value, meta]) => {
        newFilters = _replaceFilter(newFilters, filter, value, meta)
      })
      setCallArgumentBatchesRef.current = []
      setFilters(newFilters)
    }, 100)
  }

  const availableFilters = FILTER_TYPES.filter(
    filterType =>
      !filters.find(filter => filter.id === filterType.id && !filter.chainId)
  )

  return (
    <ListFilterContext.Provider
      value={{
        group,
        filters,
        availableFilters,
        addFilter,
        removeFilter,
        setFilterValue,
        chainingFilter,
        startChaining,
        endChaining,
        addChainLink,
        removeChainLink,
      }}
    >
      {children}
    </ListFilterContext.Provider>
  )
}

export const useListFilters = () => {
  const context = useContext(ListFilterContext)
  if (context === undefined) {
    throw new Error('useListFilters must be used within a ListFilterProvider')
  }
  return context
}
