import { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useDebounce } from 'use-debounce'
import { SelectField } from '@politechdev/blocks-design-system'
import { CardError } from 'components'
import { useRequest } from 'hooks'
import { fetchCanvasser, fetchCanvassers } from 'requests/canvassers'
import { useCurrent } from 'contexts/index'
import { buildParams } from './utils'

const CanvasserSelectField = ({
  label,
  filters,
  onSelect,
  selectedCanvasser,
  disableNonCollectingTurfs,
  ...props
}) => {
  const { t } = useTranslation()

  const { doesCurrentUserHavePermission } = useCurrent()
  const hasPermission = doesCurrentUserHavePermission({
    resource: 'canvasser',
    ability: 'view',
  })

  const [canvassers, setCanvassers] = useState([])
  const [fetchedCanvasser, setFetchedCanvasser] = useState()

  const { makeRequest, isLoading, errorMsg } = useRequest(fetchCanvassers, {
    onSuccess: ({ canvassers }) => setCanvassers(canvassers),
  })

  const { makeRequest: fetchSelectedCanvasser } = useRequest(fetchCanvasser, {
    onSuccess: ({ canvasser }) => setFetchedCanvasser(canvasser),
  })
  const [query, setQuery] = useState('')
  const [debounced] = useDebounce(query, 300)

  useEffect(() => {
    const params = buildParams({
      query: debounced,
      filters,
    })
    hasPermission &&
      selectedCanvasser?.id &&
      fetchSelectedCanvasser(selectedCanvasser.id, params)
  }, [selectedCanvasser])

  const getCanvassers = () => {
    const params = buildParams({
      query: debounced,
      filters,
    })

    hasPermission && makeRequest(params)
  }

  useEffect(() => {
    getCanvassers()
  }, [debounced])

  const buildCanvasserOptions = canvasser => ({
    value: canvasser.id,
    label: `${canvasser.full_name} - ${canvasser.turf?.name} ${
      disableNonCollectingTurfs && !canvasser.turf.voter_registration_enabled
        ? '(Managing)'
        : ''
    }`,
    voterRegistrationEnabled: !!canvasser.turf.voter_registration_enabled,
    disabled: disableNonCollectingTurfs
      ? !canvasser.turf.voter_registration_enabled
      : false,
    originalCanvasser: canvasser,
  })

  const options = useMemo(
    () =>
      [
        ...(canvassers || []),
        ...(fetchedCanvasser && !query ? [fetchedCanvasser] : []),
      ]
        .reduce(
          (options, option) =>
            options.find(({ id }) => id === option.id)
              ? options
              : [...options, option],
          []
        )
        .map(buildCanvasserOptions),
    [
      JSON.stringify(canvassers || []),
      JSON.stringify(fetchedCanvasser && !query ? [fetchedCanvasser] : []),
    ]
  )

  return (
    <div>
      <CardError hide={!errorMsg} message={errorMsg} />
      <SelectField
        label={label || t('Canvasser')}
        options={options}
        onSelect={id => {
          onSelect(
            options.find(option => option.value === id).originalCanvasser
          )
        }}
        onInputChange={setQuery}
        value={selectedCanvasser?.id}
        loading={isLoading}
        disabled={!hasPermission}
        onClear={() => {
          setQuery('')
          onSelect(null)
        }}
        hint={
          !hasPermission && t('You do not have permission to edit this field')
        }
        {...props}
      />
    </div>
  )
}

CanvasserSelectField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  filters: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
  selectedCanvasser: PropTypes.object,
  required: PropTypes.bool,
}

CanvasserSelectField.defaultProps = {
  id: 'canvasser',
  filters: [],
  selectedCanvasser: null,
}

export default CanvasserSelectField
