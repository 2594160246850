import { useRef, useState } from 'react'
import { formatLegacyErrorMsg, formatApiError } from 'utils/formatting'
import { logErrorResponse } from 'utils/logging'
import useEvent from './useEvent'

const defaultRequestData = {
  errors: {},
  hasErrors: false,
  isRequestComplete: false,
  isLoading: false,
}

export const useRequest = (request, { onSuccess, onError } = {}, opts = {}) => {
  const latestInit = useRef()
  const [requestData, setRequestData] = useState(defaultRequestData)

  const clearRequest = () => setRequestData(defaultRequestData)

  const makeRequest = async (...args) => {
    const trace = new Error().stack.split('\n').slice(1).join('\n')

    const initTime = Date.now()
    latestInit.current = initTime
    setRequestData({
      ...defaultRequestData,
      isLoading: true,
    })

    try {
      const response = await request(...args)

      if (latestInit.current === initTime) {
        typeof onSuccess === 'function' && (await onSuccess(response))

        setRequestData({
          ...defaultRequestData,
          response,
          isRequestComplete: true,
        })

        return response
      }
    } catch (error) {
      if (latestInit.current === initTime) {
        if (error.name === 'AbortError') {
          setRequestData(defaultRequestData)
          return
        }
        if (error.name !== 'RequestException') {
          throw error
        }

        const errors = await formatApiError(error)

        if (typeof onError === 'function') {
          await onError(error, args)
        } else if (
          opts.reportErrors !== false &&
          error.status !== 500 &&
          error.json.reason !== 'authentication failed'
        ) {
          logErrorResponse(error, args, 'Unexpected response', trace)
        }

        setRequestData({
          errors,
          isLoading: false,
          hasErrors: true,
          isRequestComplete: true,
        })

        return errors
      }
    }
  }

  return {
    clearRequest,
    makeRequest,
    ...requestData,
  }
}

useRequest.MAX_CACHE_LENGTH = 10

export const DEPRECATED_useRequest = (
  request,
  onSuccess,
  formatError = formatLegacyErrorMsg
) => {
  const [errorMsg, setErrorMsg] = useState('')
  const handleOnErrors = async error => {
    const message = await formatError(error)
    setErrorMsg(message)
  }
  const requestUtils = useRequest(request, {
    onSuccess,
    onError: handleOnErrors,
  })

  return {
    errorMsg,
    ...requestUtils,
  }
}

const cache = {}
export const useCachedRequest = (
  request,
  { onSuccess, onError } = {},
  { timeToStale = 2000 } = {}
) => {
  const cachedRequest = useEvent(async (key, ...args) => {
    if (cache[key] && !cache[key].isStale) {
      return cache[key]
    }
    cache[key] = request(...args)
    cache[key].isStale = false
    setTimeout(() => {
      cache[key].isStale = true
    }, timeToStale)
    return cache[key]
  })

  return useRequest(cachedRequest, { onSuccess, onError })
}
