/* eslint-disable arrow-body-style */
import React, { useCallback, useEffect, useState } from 'react'
import { View, ViewContainer, HeaderBlock, Paginator, Sticky } from 'components'
import {
  Button,
  ButtonBlock,
  Font,
  TextBlock,
  SelectField,
} from '@politechdev/blocks-design-system'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router'
import useLocalForm from 'hooks/useLocalForm'
import { useRequest } from 'hooks/useRequest'
import { fetchPackets, fetchShift, postDigitalBatch } from 'requests/shifts'
import {
  DataEntryUploadStorage,
  DataEntryFormStorage,
} from 'shifts/storageUtils'
import { useRouteQueryParams } from 'hooks/router'
import { logErrorResponse } from 'utils/logging'
import SubmitOrContinue from './SubmitOrContinue'
import CancelDataEntryModal from './CancelDataEntryModal'
import IneligibleForEntry from './IneligibleForEntry'
import {
  filenameCleanupRegex,
  formatForSubmission,
  getIsAllScansSelected,
  getOptionArrayOfLength,
  scrollToTop,
  generateFormConfig,
  checkSectionsNotEmpty,
  getDefaultFields,
  checkDoesVDRNeedAttention,
  checkHasRequiredFields,
  checkHasMissingFields,
  withCopiedFields,
} from './utils'
import FormRenderer from './FormRenderer'
import styles from './ShiftDataEntry.module.scss'
import PotentialIssuesModal from './components/PotentialIssuesModal'
import DocumentPreselect from './DocumentPreselect'

const ShiftDataEntry = () => {
  const { t } = useTranslation()
  const { shiftId: shiftIdString, pageId: pageIdString } = useParams()
  const history = useHistory()
  const [queryParams] = useRouteQueryParams()
  const continueWithoutScans = queryParams.continueWithoutScans === 'true'

  const [packetExists, setPacketExists] = useState(false)
  const [formConfig, setFormConfig] = useState([])
  const [isNoFormConfig, setIsNoFormConfig] = useState(false)
  const [shift, setShift] = useState()
  const [isScansExpired, setIsScansExpired] = useState()

  const [pageCount, setPageCount] = useState(-1)
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
  const [submitAttempted, setSubmitAttempted] = useState(false)
  const [isNewForm, setIsNewForm] = useState(true)

  const [isIssuesModalOpen, setIsIssuesModalOpen] = useState(false)
  const [potentialIssues, setPotentialIssues] = useState(null)

  const pageId = parseInt(pageIdString)
  const shiftId = parseInt(shiftIdString)

  const localForm = useLocalForm({})
  const {
    formData,
    setFormData,
    setFieldError,
    areFieldsValid,
    setField,
    getField,
    mergeWithFieldErrors,
  } = localForm

  const handleSuccesfulUpload = async () => {
    await DataEntryFormStorage.removeFromStorage(shiftId)
    await DataEntryUploadStorage.removeFromStorage(shiftId)
    history.push(`/collect/voter_registration/shifts`)
  }

  const {
    makeRequest: submitDataEntryReq,
    isLoading: submitDataIsLoading,
    hasErrors: submitHasErrors,
    errors: submitErrors,
  } = useRequest(postDigitalBatch, {
    onSuccess: handleSuccesfulUpload,
    onError: (error, args) => {
      const storedRecord = DataEntryFormStorage.getFromStorage(shiftId)
      logErrorResponse(
        error,
        [...args, storedRecord],
        'Custom data entry submit error'
      )
    },
  })

  const {
    makeRequest: fetchShiftReq,
    isRequestComplete: isShiftRequestComplete,
  } = useRequest(fetchShift, {
    onSuccess: async ({ shift }) => {
      const loadedConfig = generateFormConfig(
        shift.turf.voter_registration_config.data_entry_sections
      )
      if (!loadedConfig.length) {
        setIsNoFormConfig(true)
      }
      setFormConfig(loadedConfig)
      if (!loadedConfig.find(section => section?.hasDocument)) {
        setField(true, `meta.documentsSelected`)
      }
      setFormData(getDefaultFields(loadedConfig))

      setShift(shift)
    },
  })

  const { makeRequest: checkDuplicateReq, isLoading: isDupeCheckLoading } =
    useRequest(fetchPackets, {
      onSuccess: ({ packets }) => {
        if (packets.length > 0) {
          setPacketExists(true)
        }
      },
    })

  const { makeRequest: getFormRequest, isLoading: isFormDataLoading } =
    useRequest(DataEntryFormStorage.getRecordFromStorage, {
      onSuccess: async scan => {
        if (scan?.formData) {
          setFormData(scan.formData)
          setIsNewForm(false)
        } else {
          setFormData(getDefaultFields(formConfig))
          setIsNewForm(true)
        }
        if (scan?.url) {
          const res = await fetch(scan.url)
          setIsScansExpired(!res.ok)
        }
        setSubmitAttempted(false)
      },
    })

  useEffect(() => {
    const shiftIsInStorage = DataEntryUploadStorage.isShiftInStorage(shiftId)
    if (!shiftIsInStorage) {
      history.push(`/collect/voter_registration/shifts`)
      return
    }

    const coverScan = DataEntryUploadStorage.getRecordFromStorage(shiftId, 0)
    const { filename } = coverScan.metadata
    const packetName = filename.replace(filenameCleanupRegex, '.pdf')

    setPageCount(DataEntryFormStorage.getCountRecordsInStorage(shiftId))

    checkDuplicateReq({
      filters: {
        rules: [
          {
            column: 'original_filename',
            operator: 'is',
            param: packetName,
          },
        ],
      },
    })
    fetchShiftReq(shiftId, {
      fields: [
        'status',
        { turf: ['voter_registration_config'] },
        { canvasser: ['id', 'full_name', 'vdrs'] },
      ],
    })
  }, [shiftId])

  useEffect(() => {
    getFormRequest(shiftId, pageId)
  }, [pageId, shiftId])

  const handleContinueWithoutScans = () => {
    scrollToTop()
    history.push(
      `/collect/voter_registration/shifts/${shiftId}/data_entry/${pageId}?continueWithoutScans=true`
    )
    setField(true, `meta.documentsSelected`)
  }

  const goToPage = page => {
    setFormData(null)
    scrollToTop()
    history.push(
      `/collect/voter_registration/shifts/${shiftId}/data_entry/${page}`
    )
  }

  const storeScanAndGotoPage = async (page, { no_vdr_match } = {}) => {
    const currentScan = DataEntryFormStorage.getRecordFromStorage(
      shiftId,
      pageId
    )

    currentScan.formData = withCopiedFields(formData)
    if (no_vdr_match) {
      currentScan.formData.extras.no_vdr_match = true
    }

    await DataEntryFormStorage.addRecordToStorage(shiftId, pageId, currentScan)
    if (pageId > pageCount) {
      setPageCount(pageId)
    }
    setFormData(null)

    goToPage(page)
  }

  const validateAndContinue = async page => {
    setSubmitAttempted(true)
    if (!areFieldsValid) {
      return
    }

    const formDataWithCopiedFields = withCopiedFields(formData)

    const sectionEmptyCheck = checkSectionsNotEmpty(
      formConfig,
      formDataWithCopiedFields
    )
    sectionEmptyCheck.forEach(([record, hasData]) => {
      setField(!hasData, `meta.${record}.isEmpty`)
    })

    if (sectionEmptyCheck.some(([, hasData]) => !hasData)) {
      return
    }

    const { hasRequiredFields, fieldErrors } = checkHasRequiredFields(
      formConfig,
      formDataWithCopiedFields
    )

    if (!hasRequiredFields) {
      mergeWithFieldErrors(fieldErrors)
      return
    }

    const { hasMissingFields, missingFieldsForDisplay } = checkHasMissingFields(
      formConfig,
      formDataWithCopiedFields
    )

    const { hasIssues: hasVDRIssues, issues: vdrIssues } =
      checkDoesVDRNeedAttention(formConfig, formDataWithCopiedFields, shift)

    if (hasMissingFields || hasVDRIssues) {
      setPotentialIssues({
        vdrIssues,
        missingFieldsForDisplay,
        hasMissingFields,
        hasVDRIssues,
      })
      setIsIssuesModalOpen(true)
      return
    }

    storeScanAndGotoPage(page)
  }

  const handleSubmitUpload = () => {
    const storedRecord = DataEntryFormStorage.getFromStorage(shiftId)
    const formattedUpload = formatForSubmission(
      formConfig,
      shiftId,
      storedRecord.data
    )
    submitDataEntryReq(shiftId, formattedUpload)
  }

  const handleLeaveDataEntry = async () => {
    history.push(`/collect/voter_registration/shifts`)
  }

  const isChecksLoading = !isShiftRequestComplete || isDupeCheckLoading

  const PaginatorDisplay = useCallback(
    () => (
      <TextBlock className={styles.paginator__container}>
        <span>
          <Trans>Page</Trans>
        </span>
        <SelectField
          className={styles.paginator__select}
          options={getOptionArrayOfLength(Math.max(pageCount, pageId))}
          value={pageId}
          onSelect={goToPage}
        />
        <span>
          <Trans>of</Trans> {Math.max(pageCount, pageId)}
        </span>
      </TextBlock>
    ),
    [pageCount, pageId]
  )

  if (
    shift &&
    !isChecksLoading &&
    (shift.status !== 'ready_for_scans' ||
      packetExists ||
      isNoFormConfig ||
      isScansExpired)
  ) {
    return (
      <IneligibleForEntry
        shiftStatus={shift.status}
        packetExists={packetExists}
        isFormNotConfigured={isNoFormConfig}
        isScansExpired={isScansExpired}
      />
    )
  }

  const isAllScansSelected = getIsAllScansSelected(shiftId)
  const configHasUploads = formConfig.some(section => section.hasDocument)
  const isEnterableWithoutScans = formConfig.some(
    section => !section.scanRequired
  )

  if (
    !isChecksLoading &&
    !continueWithoutScans &&
    (isAllScansSelected || !configHasUploads) &&
    pageId > pageCount
  ) {
    return (
      <SubmitOrContinue
        onReview={() => goToPage(1)}
        canContinue={isEnterableWithoutScans}
        onSubmit={handleSubmitUpload}
        onContinue={handleContinueWithoutScans}
        isLoading={submitDataIsLoading}
        noUploadsInConfig={!configHasUploads}
        hasErrors={submitHasErrors}
        submitErrors={submitErrors}
      />
    )
  }

  const enableActions =
    continueWithoutScans ||
    !configHasUploads ||
    (configHasUploads && getField('meta.documentsSelected'))

  const dataEntryHasDocuments = !!formConfig.find(
    ({ hasDocument }) => hasDocument
  )

  if (dataEntryHasDocuments && !getField('meta.documentsSelected')) {
    return (
      <DocumentPreselect
        sections={formConfig}
        fieldSetter={field => value => {
          setFieldError(field, null)
          setField(value, field)
        }}
      />
    )
  }

  return (
    <View>
      <HeaderBlock title={t('Data entry')} className={styles.header} />
      <Sticky className={styles.sticky}>
        <div className={styles.sticky__actions}>
          <Paginator
            currentPage={pageId}
            totalPages={Math.max(pageCount, pageId)}
            onNext={() => goToPage(pageId + 1)}
            onPrevious={() => goToPage(pageId - 1)}
            CustomDisplay={PaginatorDisplay}
          />
          {!areFieldsValid && submitAttempted && (
            <div className={styles['button-hint']}>
              <Font.Copy
                Element="p"
                variant="hint"
                className={styles['error-text']}
              >
                {t('Please check for invalid fields.')}
              </Font.Copy>
            </div>
          )}
          {enableActions && (
            <ButtonBlock justify="right">
              <Button.Accent
                onClick={() => validateAndContinue(pageId + 1)}
                disabled={!areFieldsValid && submitAttempted}
              >
                {isNewForm ? t('Save') : t('Update')}
              </Button.Accent>
              <Button.Secondary onClick={() => setIsCancelModalOpen(true)}>
                {t('Cancel')}
              </Button.Secondary>
            </ButtonBlock>
          )}
        </div>
      </Sticky>
      <ViewContainer loading={isChecksLoading || isFormDataLoading}>
        {shift ? (
          <FormRenderer
            formConfig={formConfig}
            localForm={localForm}
            submitAttempted={submitAttempted}
            setField={setField}
          />
        ) : null}
      </ViewContainer>
      <CancelDataEntryModal
        isOpen={isCancelModalOpen}
        setIsOpen={setIsCancelModalOpen}
        onConfirm={handleLeaveDataEntry}
      />
      {shift ? (
        <PotentialIssuesModal
          isOpen={isIssuesModalOpen}
          setIsOpen={setIsIssuesModalOpen}
          canvasser={shift.canvasser}
          county={getField('county')}
          potentialIssues={potentialIssues}
          onConfirm={options => {
            storeScanAndGotoPage(pageId + 1, options)
          }}
        />
      ) : null}
    </View>
  )
}

export default ShiftDataEntry
